import React from 'react'

import styles from '../styles/Steps.module.css'

const Steps = ({ step, setStep, inputBandMeasurement }) => {
  const steps = [1, 2, 3]

  const onClick = (s) => {
    if (s === 1) {
      setStep(0)
    } else if (s === 2) {
      setStep(1)
    } else if (s === 3 && inputBandMeasurement) {
      setStep(2)
    }
  }
  return (
    <div className={styles.steps}>
      {steps.map((s) => (
        <div
          key={s}
          onClick={() => onClick(s)}
          role="button"
          className={`${styles['step-number']} ${step === s ? styles['chosen-number'] : ''} ${
            s === 3 && !inputBandMeasurement ? styles['not-allowed'] : ''
          }`}>
          {s}
        </div>
      ))}
    </div>
  )
}

export default Steps
