import React from 'react'

import PulseLoader from './PulseLoader'
import styles from '../styles/PulseLoader.module.css'

const PulseLoaderWithBackground = ({ isLoading }) => {
  return (
    <div className={styles['add-to-cart-popup-pulse-loader-fade']}>
      {isLoading && (
        <div className={styles['loader-container']}>
          <PulseLoader className={styles.loader} />
        </div>
      )}
    </div>
  )
}

export default PulseLoaderWithBackground
