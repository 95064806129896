import styles from '../styles/Button.module.css'

const Button = ({ onClick, variant, text, image, arrow = 'right', disabled = false, firstStep = false }) => {
  return (
    <button
      className={`${styles[variant]} ${styles.button} ${firstStep ? styles['column-center'] : ''}`}
      onClick={onClick}
      disabled={disabled}>
      {image && <img src={image} alt="arrow" className={styles[`column-${arrow}`]} />}
      <p className={styles['column-center']}>{text}</p>
    </button>
  )
}

export default Button
