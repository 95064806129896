import TagManager from 'react-gtm-module'

import AUcups from './data/au/AU-cups.json'
import EUcups from './data/EUcups.json'
import FRcups from './data/fr/FR-cups.json'
import ITcups from './data/it/IT-cups.json'
import UKcups from './data/uk/UK-cups.json'
import UScups from './data/us/US-cups.json'

export const handleGetSizes = (inputBustMeasurement, inputBandMeasurement, bandSize, chart, setCup) => {
  let contempArr = []
  let adjustedBustMeasurement = inputBustMeasurement
  if (bandSize) {
    for (let band of bandSize) {
      let cup = ''
      if (
        inputBandMeasurement.includes('<') ||
        inputBandMeasurement.includes('>') ||
        inputBustMeasurement.includes('<') ||
        inputBustMeasurement.includes('>')
      ) {
        return ['size unavailable']
      }
      if (chart === 'eu') {
        cup = EUcups[band][adjustedBustMeasurement]
      } else if (chart === 'uk') {
        cup = UKcups[band][adjustedBustMeasurement]
      } else if (chart === 'us') {
        cup = UScups[band][adjustedBustMeasurement]
      } else if (chart === 'au') {
        cup = AUcups[band][adjustedBustMeasurement]
      } else if (chart === 'fr') {
        cup = FRcups[band][adjustedBustMeasurement]
      } else if (chart === 'it') {
        cup = ITcups[band][adjustedBustMeasurement]
      }
      if (cup) {
        setCup(cup)
        const bandAndCup = getBandAndCup(band, cup)
        for (let bc of bandAndCup) {
          contempArr.push(bc)
        }
      }
    }
  }
  if (contempArr.length > 0) {
    return contempArr
  } else {
    return []
  }
}

const getBandAndCup = (band, cup) => {
  const bc = []
  for (let c of cup) {
    bc.push(band + c)
  }
  return bc
}

export const optionsRange = (start, stop, incr) => {
  let opts = []
  for (let i = start; i <= stop; ) {
    opts.push(i)
    i += incr
  }
  return opts
}

export const getChart = (query) => {
  switch (query) {
    case 'gb':
    case 'uk':
    case 'ie':
      return 'uk'
    case 'au':
    case 'nz':
      return 'au'
    case 'fr':
    case 'pt':
    case 'es':
    case 'mx':
    case 'cl':
      return 'fr'
    case 'us':
    case 'ca':
      return 'us'
    case 'it':
    case 'cz':
      return 'it'
    default:
      return 'eu'
  }
}

const pushToDataLayer = (data) => {
  TagManager.dataLayer(data)
}

export const sendInputToGTM = (event, inputBustMeasurement, inputBandMeasurement, sizes) => {
  pushToDataLayer({
    dataLayer: {
      event: event,
      input_band: inputBandMeasurement,
      input_bust: inputBustMeasurement,
      combined_measurements: inputBandMeasurement + '-' + inputBustMeasurement,
      size_suggestion: sizes,
      match: event === 'size_suggestion_success' ? true : false,
    },
  })
}

export const getRange = (bandSize, chart) => {
  let useChart
  if (chart === 'eu') {
    useChart = EUcups
  } else if (chart === 'uk') {
    useChart = UKcups
  } else if (chart === 'us') {
    useChart = UScups
  } else if (chart === 'au') {
    useChart = AUcups
  } else if (chart === 'fr') {
    useChart = FRcups
  } else if (chart === 'it') {
    useChart = ITcups
  }
  const arr = Object.keys(useChart[bandSize])
  return arr.sort((a, b) => a - b)
}

export const linkToBras = (size, bandSize, cup) => {
  if (size.length === 1) {
    return `https://www.missmary.com/bra/?size_cup_type=${cup[0]}&size_type=${bandSize}`
  } else if (size.length === 2) {
    return `https://www.missmary.com/bra/?size_cup_type=${cup[0]}&size_cup_type=${cup[1]}&size_type=${bandSize}`
  } else {
    return 'https://www.missmary.com/bra/'
  }
}

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const findSmallestOrLargestMeasurement = (obj, order = 'asc') => {
  const keys = Object.keys(obj)
  const smallest = Math.min(...keys)
  const largest = Math.max(...keys)
  return order === 'asc' ? smallest : largest
}

export const findBandSize = (inputBandMeasurement, chartToUse) => {
  if (inputBandMeasurement.includes('<')) {
    const smallest = Math.min(...Object.keys(chartToUse).map((key) => +key))
    return chartToUse[smallest]
  } else if (inputBandMeasurement.includes('>')) {
    const largest = Math.max(...Object.keys(chartToUse).map((key) => +key))
    return chartToUse[largest]
  }
  return chartToUse[inputBandMeasurement]
}
