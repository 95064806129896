import * as React from 'react'

import TagManager from 'react-gtm-module'
import { Route, Routes } from 'react-router-dom'

import CalculatorQuiz from './components/CalculatorQuiz'

const tagManagerArgs = {
  gtmId: 'GTM-NXT7SJ4',
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Routes>
      <Route path="/" element={<CalculatorQuiz />} />
    </Routes>
  )
}

export default App
