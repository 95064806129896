import React from 'react'

import { useTranslation } from 'react-i18next'

import chevron from '../assets/icons/chevron.svg'
import { findSmallestOrLargestMeasurement } from '../helpers'
import quizStyles from '../styles/CalculatorQuiz.module.css'

const MeasurementInput = ({
  inputMeasurement,
  onChangeFunction,
  inchesCharts,
  chart,
  setSmaller,
  setLarger,
  bandSizes = undefined,
  bandSize = undefined,
  getRange = undefined,
}) => {
  const { t } = useTranslation()
  const smallestValue = getRange ? getRange(bandSize, chart)[0] : findSmallestOrLargestMeasurement(bandSizes)
  const largestValue = getRange
    ? getRange(bandSize, chart)[getRange(bandSize, chart).length - 1]
    : findSmallestOrLargestMeasurement(bandSizes, 'desc')

  const generateOptions = () => {
    if (getRange && bandSize) {
      const options = getRange(bandSize, chart).map((key, i) => {
        return (
          <option key={i} value={key}>
            {key} {['uk', 'us'].includes(chart) ? ' in' : ' cm'}
          </option>
        )
      })
      return options
    } else if (bandSizes) {
      const options = Object.keys(bandSizes)
        .sort((a, b) => a - b)
        .map((key, i) => {
          return (
            <option key={i} value={key}>
              {key} {['uk', 'us'].includes(chart) ? ' in' : ' cm'}
            </option>
          )
        })
      return options
    }
  }

  const onChangeHandler = (e) => {
    onChangeFunction(e.target.value)
    if (e.target.value.includes('<')) {
      setSmaller(true)
      setLarger(false)
    } else if (e.target.value.includes('>')) {
      setSmaller(false)
      setLarger(true)
    } else {
      setSmaller(false)
      setLarger(false)
    }
  }

  return (
    <div className={quizStyles['input-wrapper']}>
      <div className={quizStyles['select-wrapper']}>
        <p className={quizStyles['selected-value']}>
          {inputMeasurement && inputMeasurement + (inchesCharts.includes(chart) ? ' in' : ' cm')}
          {!inputMeasurement && t('select_measurement')}
        </p>
        <img src={chevron} alt="chevron" className={quizStyles.chevron} />
        <select className={quizStyles.select} onChange={onChangeHandler} value={inputMeasurement || ''}>
          <option value="" disabled hidden>
            {t('select_measurement')}
          </option>
          <option value={'< ' + smallestValue}>
            {` < ${smallestValue} ${['uk', 'us'].includes(chart) ? ' in' : ' cm'}`}
          </option>
          {generateOptions()}
          <option value={'> ' + largestValue}>
            {` > ${largestValue} ${['uk', 'us'].includes(chart) ? ' in' : ' cm'}`}
          </option>
        </select>
      </div>
    </div>
  )
}

export default MeasurementInput
