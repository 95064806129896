import { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Button from './Button'
import FirstPage from './FirstPage'
import MeasurementInput from './MeasurementInput'
import PulseLoaderWithBackground from './PulseLoaderWithBackground'
import Results from './Results'
import Steps from './Steps'
import arrowWhite from '../assets/icons/arrow-white.svg'
import arrow from '../assets/icons/arrow.svg'
import bandsize from '../assets/icons/bandsize.svg'
import bustsize from '../assets/icons/bustsize.svg'
import AUBandSizes from '../data/au/AU-bands.json'
import bandSizes from '../data/EUbandsizes.json'
import FRBandSizes from '../data/fr/FR-bands.json'
import ITBandSizes from '../data/it/IT-bands.json'
import UKBandSizes from '../data/uk/UK-bands.json'
import USBandSizes from '../data/us/US-bands.json'
import { findBandSize, getChart, getRange, handleGetSizes, scrollToTop, sendInputToGTM } from '../helpers'
import quizStyles from '../styles/CalculatorQuiz.module.css'
import '../styles/globals.css'

const CalculatorQuiz = () => {
  const [showFirstPage, setShowFirstPage] = useState(true)
  const [step, setStep] = useState(0)
  const [quizFinished, setQuizFinished] = useState(false)
  const [chart, setChart] = useState('')
  const [paramChart, setParamChart] = useState('')
  const [inputBandMeasurement, setInputBandMeasurement] = useState('')
  const [inputBustMeasurement, setInputBustMeasurement] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [lang, setLang] = useState('')
  const [showSize, setShowSize] = useState(false)
  const [size, setSize] = useState('')
  const [cup, setCup] = useState('')
  const [smallerBand, setSmallerBand] = useState(false)
  const [smallerBust, setSmallerBust] = useState(false)
  const [largerBand, setLargerBand] = useState(false)
  const [largerBust, setLargerBust] = useState(false)
  const location = useLocation()
  const centimeterCharts = ['eu', 'au', 'fr', 'it']
  const inchesCharts = ['us', 'uk']

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    setLang(queryParams.get('lang'))
    setParamChart(queryParams.get('chart'))
    setChart(getChart(queryParams.get('chart')))
  }, [location])

  const { t, i18n } = useTranslation()
  useEffect(() => {
    const setLanguage = () => {
      i18n.changeLanguage(lang)
    }
    setLanguage()
  }, [lang, i18n])

  const bandSize = useMemo(() => {
    let chartToUse = bandSizes
    if (chart === 'us') {
      chartToUse = USBandSizes
    } else if (chart === 'au') {
      chartToUse = AUBandSizes
    } else if (chart === 'fr') {
      chartToUse = FRBandSizes
    } else if (chart === 'it') {
      chartToUse = ITBandSizes
    } else if (chart === 'uk') {
      chartToUse = UKBandSizes
    }
    const returnSize = findBandSize(inputBandMeasurement, chartToUse)
    // only resets input if it's not in the range of the chart
    if (returnSize && !getRange(returnSize, chart).includes(inputBustMeasurement)) {
      setInputBustMeasurement('')
    }
    return returnSize
  }, [inputBandMeasurement, chart])

  useEffect(() => {
    setShowSize(false)
    setSize([])
  }, [inputBandMeasurement, inputBustMeasurement, chart])

  const handleShowSize = () => {
    const getSizes = handleGetSizes(inputBustMeasurement, inputBandMeasurement, bandSize, chart, setCup)
    const eventName =
      smallerBand || smallerBust || largerBand || largerBust ? 'size_suggestion_fail' : 'size_suggestion_success'
    if (getSizes.length) {
      setSize(getSizes)
      setShowSize(true)
      sendInputToGTM(eventName, inputBustMeasurement, inputBandMeasurement, getSizes)
    }
  }

  const reset = () => {
    setShowFirstPage(true)
    setStep(0)
    setShowSize(false)
    setIsLoading(false)
    setSize('')
    setInputBandMeasurement('')
    setInputBustMeasurement('')
    setCup('')
  }

  const nextStep = () => {
    if (step === 0) {
      setStep(step + 1)
    } else if (step === 1 && inputBandMeasurement) {
      setStep(step + 1)
    } else if (step === 2 && inputBustMeasurement) {
      setIsLoading(true)
      setStep(null)
      setQuizFinished(true)
      handleShowSize()
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
    setTimeout(() => {
      scrollToTop()
    }, 100)
  }

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const redoQuiz = () => {
    setStep(0)
    setQuizFinished(false)
    reset()
  }

  return (
    <div className={quizStyles['beige-container']}>
      {showFirstPage && <FirstPage setShowFirstPage={setShowFirstPage} />}
      {!showFirstPage && isLoading && (
        <div className={quizStyles['loading-wrapper']}>
          <PulseLoaderWithBackground isLoading={isLoading} />
        </div>
      )}
      {!showFirstPage && !isLoading && (
        <div className={quizStyles['inner-wrapper']}>
          {!quizFinished && <Steps step={step + 1} setStep={setStep} inputBandMeasurement={inputBandMeasurement} />}
          {step === 0 && (
            <div className={quizStyles['quiz-container']}>
              <div className={quizStyles['instructions-container']}>
                <h2>{t('stepone_title')}</h2>
                <video autoPlay loop muted playsInline className={quizStyles['video']}>
                  <source src="https://player.vimeo.com/progressive_redirect/playback/824105836/rendition/1080p/file.mp4?loc=external&signature=ef85993f5b1aa05fd22aadd6f42aa869e347dcc0b30570284951ae263d9e3ee1" />
                  <img src="../assets/measure1firststep.png" alt="" />
                </video>
                <p>{t('stepone_instructions')}</p>
              </div>
            </div>
          )}
          {step === 1 && (
            <div className={quizStyles['quiz-container']}>
              <div className={quizStyles['instructions-container']}>
                <h2>{t('band_measurement')}</h2>
                <video autoPlay loop muted playsInline className={quizStyles['video']}>
                  <source src="https://player.vimeo.com/progressive_redirect/playback/832562270/rendition/1080p/file.mp4?loc=external&signature=e30105a7be665ae171b30ffd465450de0b1fe2a66ccdf31422b5d515203883b5" />
                  <img src={bandsize} alt="band measurement" width="84px" height="97px" />
                </video>
                <p>{t('band_instructions')}</p>
              </div>
              {centimeterCharts.includes(chart) && (
                <MeasurementInput
                  inputMeasurement={inputBandMeasurement}
                  onChangeFunction={setInputBandMeasurement}
                  inchesCharts={inchesCharts}
                  chart={chart}
                  setSmaller={setSmallerBand}
                  setLarger={setLargerBand}
                  bandSizes={bandSizes}
                />
              )}
              {inchesCharts.includes(chart) && (
                <MeasurementInput
                  inputMeasurement={inputBandMeasurement}
                  onChangeFunction={setInputBandMeasurement}
                  inchesCharts={inchesCharts}
                  chart={chart}
                  setSmaller={setSmallerBand}
                  setLarger={setLargerBand}
                  bandSizes={UKBandSizes}
                />
              )}
            </div>
          )}
          {step === 2 && (
            <div className={quizStyles['quiz-container']}>
              <div className={quizStyles['instructions-container']}>
                <h2>{t('bust_measurement')}</h2>
                <video autoPlay loop muted playsInline className={quizStyles['video']}>
                  <source src="https://player.vimeo.com/progressive_redirect/playback/832558789/rendition/1080p/file.mp4?loc=external&signature=ce04f298064c0163ebe491c0f72390cfe4af3d7ade6de1e36f4731247aa4a481" />
                  <img src={bustsize} alt="bust measurement" width="84px" height="97px" />
                </video>
                <p>{t('bust_instructions')}</p>
              </div>
              <MeasurementInput
                inputMeasurement={inputBustMeasurement}
                onChangeFunction={setInputBustMeasurement}
                inchesCharts={inchesCharts}
                chart={chart}
                setSmaller={setSmallerBust}
                setLarger={setLargerBust}
                bandSizes={UKBandSizes}
                bandSize={bandSize}
                getRange={getRange}
              />
            </div>
          )}
          {quizFinished ? (
            <div className={quizStyles['quiz-container']}>
              {showSize && (
                <Results
                  size={size}
                  chart={chart}
                  lang={lang}
                  paramChart={paramChart}
                  smallerBand={smallerBand}
                  smallerBust={smallerBust}
                  largerBand={largerBand}
                  largerBust={largerBust}
                  bandSize={bandSize}
                  redoQuiz={redoQuiz}
                  cup={cup}
                />
              )}
            </div>
          ) : (
            <div className={quizStyles['controllers-wrapper']}>
              {step === 0 && (
                <Button
                  onClick={() => setShowFirstPage(true)}
                  variant="outlined"
                  text={t('back')}
                  image={arrow}
                  arrow="left"></Button>
              )}
              {step > 0 && (
                <Button onClick={goBack} variant="outlined" text={t('back')} image={arrow} arrow="left"></Button>
              )}
              <Button
                onClick={nextStep}
                variant="black"
                text={step === 2 ? t('get_results') : t('next')}
                image={arrowWhite}
                disabled={(step === 1 && !inputBandMeasurement) || (step === 2 && !inputBustMeasurement)}
                firstStep={step === 0}></Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default CalculatorQuiz
