import { useTranslation } from 'react-i18next'

import checkmark from '../assets/icons/checkmark-blk.svg'
import styles from '../styles/Sizes.module.css'

const Sizes = ({ size, chart }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className={styles.sizes}>
        <img className={styles.checkmark} src={checkmark} alt="checkmark" />
        <div>{size.join(' - ')}</div>
      </div>
      <p className={styles['fs-smaller']}>*{t(`chart_type_${chart}`)}</p>
    </div>
  )
}

export default Sizes
