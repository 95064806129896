import React from 'react'

import styles from '../styles/PulseLoader.module.css'

const PulseLoader = () => {
  return (
    <div className={styles['pulse-loader']}>
      <div />
      <div />
      <div />
    </div>
  )
}

export default PulseLoader
