import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from './Button'
import Sizes from './Sizes'
import disclaimer from '../assets/icons/disclaimer.svg'
import redoBlack from '../assets/icons/redo-blk.svg'
import redoWhite from '../assets/icons/redo.svg'
import email from '../data/email.json'
import { linkToBras } from '../helpers'
import buttonStyles from '../styles/Button.module.css'
import quizStyles from '../styles/CalculatorQuiz.module.css'

const Results = ({
  size,
  chart,
  lang,
  paramChart,
  smallerBand,
  smallerBust,
  largerBand,
  largerBust,
  bandSize,
  redoQuiz,
  cup,
}) => {
  const { t } = useTranslation()
  const noPerfectMatch = smallerBand || smallerBust || largerBand || largerBust
  const contactEmail = email[paramChart] || email['us']
  return (
    <div className={quizStyles['results-wrapper']}>
      {noPerfectMatch ? (
        <div className={quizStyles['disclaimer-wrapper']}>
          <div>
            <img src={disclaimer} alt="disclaimer" className={quizStyles.disclaimer} />
            <p>{t('unsuccessful')}</p>
          </div>
          <Button onClick={redoQuiz} variant="black" text={t('try_again')} image={redoWhite} arrow="together"></Button>
          <p>{t('further_assistance')}</p>
          <a
            href={`https://support.missmary.com/hc/${paramChart}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonStyles['button']} ${buttonStyles['outlined']}`}>
            <p className={buttonStyles['column-center']}>{t('contact_us')}</p>
          </a>
          <p>{t('get_in_touch')}</p>
          <a
            href={`mailto: ${contactEmail}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonStyles['button']} ${buttonStyles['recalculate']}`}>
            <p className={buttonStyles['culumn-center']}>{contactEmail}</p>
          </a>
        </div>
      ) : (
        <div className={quizStyles['size-suggestion-wrapper']}>
          <div>
            <p>{t('size_suggestion')}</p>
            <Sizes size={size} chart={chart} lang={lang} paramChart={paramChart} />
          </div>
          <a
            href={linkToBras(size, bandSize, cup)}
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonStyles.button} ${buttonStyles.black} ${buttonStyles['column-center']}`}>
            <p className={buttonStyles['column-center']}>{t('browse_bras', { size: size.join('-') })}</p>
          </a>
          <Button
            onClick={redoQuiz}
            variant="recalculate"
            text={t('recalculate')}
            image={redoBlack}
            arrow="right"></Button>
          <p>{t('further_assistance')}</p>
          <a
            href={`https://support.missmary.com/hc/${paramChart}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`${buttonStyles['button']} ${buttonStyles['outlined']}`}>
            <p className={buttonStyles['column-center']}>{t('contact_us')}</p>
          </a>
        </div>
      )}
    </div>
  )
}

export default Results
