import React from 'react'

import { Trans, useTranslation } from 'react-i18next'

import Button from './Button'
import quizStyles from '../styles/CalculatorQuiz.module.css'
import styles from '../styles/FirstPage.module.css'

const FirstPage = ({ setShowFirstPage }) => {
  const { t } = useTranslation()
  return (
    <div className={quizStyles['inner-wrapper']}>
      <div className={styles['firstpage-container']}>
        <div className={styles['important-message']}>
          <p>{t('important_info')}</p>
        </div>
        <h1 className={styles.header}>{t('before_start')}</h1>
        <div className={styles['text-container']}>
          <div>
            <Trans i18nKey={'estimated_size'} components={<strong>placeholder</strong>} />
          </div>
          <div>
            <Trans i18nKey={'disclaimer'} components={<strong>placeholder</strong>} />
          </div>
          <div>
            <Trans i18nKey={'safe_hands'} components={<strong>placeholder</strong>} />
          </div>
        </div>
        <Button onClick={() => setShowFirstPage(false)} variant="black" text={t('start_btn')}></Button>
      </div>
    </div>
  )
}

export default FirstPage
